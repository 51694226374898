import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../components/Common/ErrorPage/ErrorPage';

import UserProfile from '../components/ProfilePage/UserProfile/UserProfile';
import SubscriptionOptions from '../components/ProfilePage/SubscriptionOptions/SubscriptionOptions';
import LoginMethods from '../components/ProfilePage/LoginMethods/LoginMethods';
import PromoCodeInput from '../components/ProfilePage/PromoCodeInput/PromoCodeInput';
import ActiveSubscriptions from '../components/ProfilePage/ActiveSubscriptions/ActiveSubscriptions';
import FamilySubscriptionManager from '../components/ProfilePage/FamilySubscriptionManager/FamilySubscriptionManager';
import Loader from '../components/Common/Loader/Loader';
import authApi from '../utils/authApi';
import ShopDiamonds from '../components/ProfilePage/Shop/ShopDiamonds';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activePurchases, setActivePurchases] = useState([]); 
  const [premiumPromocode, setPremiumPromocode] = useState(null);
  const [premiumFeatures, setPremiumFeatures] = useState({});
  const [prices, setPrices] = useState(null);
  const [productsInfo, setProductsInfo] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const startTime = performance.now(); // Засекаем время начала
  
    try {
      setIsLoading(true);
      // Запускаем все запросы одновременно
      const [
        userResponse,
        purchasesResponse,
        premiunFeaturesResponse,
        pricesResponse
      ] = await Promise.all([
        authApi.get(`/api/v1/user/fetchAllData`),
        authApi.get('/api/v1/purchase/getActivePurchases'),
        authApi.get('/api/v1/purchase/hasPaidFeatures'),
        authApi.get('/api/v1/user/products/getPrices'), 
      ]);
  
      // Устанавливаем полученные данные
      setUserData(userResponse.data);
      setActivePurchases(purchasesResponse.data || []);
      setPremiumPromocode(userResponse.data.purchasing?.premium_promocode || null);
      setPrices(pricesResponse.data);


      if (premiunFeaturesResponse.status === 200) {
        setPremiumFeatures(premiunFeaturesResponse.data);
      }
  

      try {
        const productsInfoResult = await authApi.get('/api/v1/purchase/products');
        const productsInfo = {
          ...productsInfoResult.data,
          publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID,
          userId: userResponse.data.id,
        };
        setProductsInfo(productsInfo);
      } catch (error) {
        console.error("Failed to fetch product info", error);
      }

  
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch user data', error);
      setError('Failed to fetch user data. Please try again later.');
      setIsLoading(false);
    } finally {
      const endTime = performance.now(); // Засекаем время окончания
      //alert(`Время выполнения fetchUserData: ${(endTime - startTime).toFixed(2)} мс`);
    }
  };
  
    
  if (isLoading) {
    return <Loader />; // Показываем лоадер, пока данные загружаются
  }

  if (error) {
    return <ErrorPage onRetry={() => window.location.reload()} />; 
  }


  // Проверяем наличие подписки premium или активного промокода
  const hasOwnPremiumAccess = Array.isArray(activePurchases) && activePurchases.some(
    (purchase) => purchase.product.grantAccess === 'premium'
  ) || premiumPromocode;

  return (
    <div className="container">
      <UserProfile userData={userData} isPremium={premiumFeatures.premium}/>
      {hasOwnPremiumAccess ? (
        <ActiveSubscriptions activePurchases={activePurchases} premiumPromocode={premiumPromocode} fetchUserData={fetchUserData} />
      ) : !premiumFeatures.premium ? (     
        <SubscriptionOptions productsInfo={productsInfo} onPurchased={fetchUserData}/>
      ): null}

      <ShopDiamonds productsInfo={productsInfo} prices={prices} onPurchased={fetchUserData}/>


      <LoginMethods userData={userData}/>
      <PromoCodeInput onPromoCodeApplied={fetchUserData} />

      <FamilySubscriptionManager hasOwnPremiumAccess={hasOwnPremiumAccess} premiumFeatures={premiumFeatures} updatePage={fetchUserData} />
    </div>
  );
};

export default ProfilePage;
