// src/components/ProfilePage/UserProfile/UserProfileCard.js
import React from 'react';
import './UserProfileCard.css';

function getRankId(ranks, points) {
  let rankId = ranks[0].id;
  for (let i = 0; i < ranks.length; i++) {
    if (points >= ranks[i].points) {
      rankId = ranks[i].id;
    } else {
      break;
    }
  }
  return rankId;
}

const UserProfileCard = ({ username, userId, frontIconUrl, backIconUrl, isPremium = false, diamonds = null }) => {
  try {
    return (
      <div className="user-container profile-section-white-box">
        <div className="icon-container">
          <img src={frontIconUrl} alt="Icon Front" className="icon icon_front" />
          <img src={backIconUrl} alt="Icon Back" className="icon icon_back" />
        </div>
        <div className="text-container">
          <div className="text-title">
            {username}
            {isPremium && (
              <span className="premium-badge">Premium</span>
            )}
          </div>
          <div className="text-description id-text">ID: {userId}</div>
        </div>
        <div className="right-elements">
          <div className="right-text">{diamonds}</div>
          <img src="/ShopDiamonds/diamonds.png" alt="Diamonds Icon" className="right-image" />
        </div>
      </div>
    );
  }
  catch (err) {
    console.error(err);
    return null;
  }
};

export default UserProfileCard;
