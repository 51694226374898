// src/components/AuthMethods/OAuthRedirectHandler.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../../context/ModalContext';
import { useTranslation } from 'react-i18next';

const OAuthRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const resultCode = queryParams.get('resultCode');
    const accessToken = queryParams.get('access');
    const refreshToken = queryParams.get('refresh');

    const handleAuthError = (code) => {
      switch (code) {
        case '411':
          return t('AuthMethods_error.auth_wrong_code');
        case '410':
          return t('AuthMethods_error.auth_registration_restricted');
        case '409':
        case '406':
          return t('AuthMethods_error.auth_conflict'); // Сообщение при конфликте авторизации
        case '401':
          return t('AuthMethods_error.auth_invalid_token'); // Сообщение при невалидном токене
        case '400':
          return t('AuthMethods_error.auth_invalid_data'); // Сообщение при некорректных данных
        case '500':
          return t('AuthMethods_error.auth_server_error'); // Сообщение при ошибке сервера
        case '404':
          return t('AuthMethods_error.auth_user_not_found'); // Сообщение при не найденном пользователе
        default:
          return t('AuthMethods_error.auth_failed'); // Общее сообщение об ошибке
      }
    };

    if (resultCode === '200' && accessToken && refreshToken) {
      // Сохраняем токены в localStorage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      
      // Перенаправляем на страницу профиля
      navigate('/profile');
    } else {
      // Показать модальное окно с соответствующим сообщением об ошибке
      const errorMessage = handleAuthError(resultCode);
      showModal(
        t('AuthMethods_error.title'),
        <p>{errorMessage}</p>,
        {
          closeButtonType: 'button',
          onClose: () => {
            hideModal();
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            
            if (accessToken && refreshToken) {
              navigate('/profile');
            } else {
              navigate('/');
            }
          },
        }
      );
    }
  }, [location, navigate, t]);

  return null; 
};

export default OAuthRedirectHandler;
