import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import AlternativePurchasing from './pages/AlternativePurchasing';
import OAuthRedirectHandler from './components/AuthMethods/OAuthRedirectHandler'; // Импортируем новый компонент
import Footer from './components/Footer/Footer';
import ModalProvider from './context/ModalContext'; // Импортируем провайдер контекста модального окна
import './App.css';

const App = () => (
  <Router>
    <ModalProvider>
      <div className="app-container">
        <Navbar />
        <div className="content-container">
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/login" element={<OAuthRedirectHandler />} />
            {/* <Route path="/google-play-purchasing-issues" element={<AlternativePurchasing />} /> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </ModalProvider>
  </Router>
);

export default App;
