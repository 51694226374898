import React, { useState, useEffect } from 'react';
import authApi from '../../../utils/authApi';
import Loader from '../../Common/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../context/ModalContext';
import UserProfileCard from '../UserProfile/UserProfileCard';
import './FamilySubscriptionManager.css';
import { useRef } from 'react';


const FamilySubscriptionManager = ({ hasOwnPremiumAccess, premiumFeatures, updatePage}) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();
  const [familyInfo, setFamilyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [memberId, setMemberId] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    fetchFamilyInfo();
  }, []);

  const fetchFamilyInfo = async () => {
    try {
      setLoading(true);
      const response = await authApi.get('/api/v1/user/family/info');
      setFamilyInfo(response.data);
    } catch {
      setFamilyInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async () => {
    try {
      const memberId = inputRef.current.value;

      setLoading(true);
      await authApi.post('/api/v1/user/family/add-member', { memberId });
      setMemberId('');
      fetchFamilyInfo();
      hideModal();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        showModal(t('Error'), <p>{t(err.response.data.message)}</p>, {
          closeButtonType: 'button',
        });
      } else {
        showModal(t('Error'), <p>{t('Failed to add family member. Please try again.')}</p>, { closeButtonType: 'button' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async (memberId) => {
    try {
      setLoading(true);
      await authApi.post('/api/v1/user/family/remove-member', { memberId });
      fetchFamilyInfo();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        showModal(t('Error'), <p>{t(err.response.data.message)}</p>, {
          closeButtonType: 'button',
        });
      } else {
        showModal(t('Error'), <p>{t('Failed to remove family member. Please try again.')}</p>, { closeButtonType: 'button' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLeaveFamily = async () => {
    try {
      setLoading(true);
      await authApi.post('/api/v1/user/family/leave');
      setFamilyInfo(null);
      updatePage();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        showModal(t('Error'), <p>{t(err.response.data.message)}</p>, {
          closeButtonType: 'button',
        });
      } else {
        showModal(t('Error'), <p>{t('Failed to leave the family subscription.')}</p>, { closeButtonType: 'button' });
      }
    } finally {
      setLoading(false);
    }
  };

  const showAddMemberModal = () => {
    showModal(
      t('Invite Member'),
      <div>
        <input type="text" placeholder={t('Enter member ID')} className="input1 input-add-member" ref={inputRef} />
        <button className="button1" onClick={handleAddMember}>
          {t('Invite Member')}
        </button>
      </div>,
      { closeButtonType: 'icon' }
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (!hasOwnPremiumAccess && !familyInfo) return null;

  const isHead = familyInfo && familyInfo.members !== undefined;

  return (
    <div className="profile-section">
      <h2 className="profile-section-title">
        {t('Family Subscription') + (familyInfo && isHead ? ` (${familyInfo.occupiedSlots || 0}/${familyInfo.availableSlots || 0})` : '')}
      </h2>

      {familyInfo === null ? (
        <div className="profile-section-white-box no-subscribtion-panel-container">
          <div className="no-subscribtion-panel">
            <span className="text-regular">{t('No family subscription found.')}</span>
          </div>
        </div>
      ) : (
        <>
          {isHead ? (
            familyInfo.members.length > 0 ? (
              <div className="family-info">
                <ul className="family-members-list">
                  {familyInfo.members.map((member) => (
                    <li key={member.id}>
                      <div className="user-card">
                        <UserProfileCard
                          username={member.username}
                          userId={member.id}
                          frontIconUrl="/icon_front.png"
                          backIconUrl="/icon_back.png"
                          rankIconUrl="/rank.png"
                        />
                      </div>
                      <button onClick={() => handleRemoveMember(member.id)} className="remove-button button1">
                        {t('Remove')}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="profile-section-white-box no-subscribtion-panel-container">
                <div className="no-subscribtion-panel">
                  <span className="text-regular">{t('No family members added yet.')}</span>
                </div>
              </div>
            )
          ) : (
            <>
              {!premiumFeatures.premium && (
                <p className="alertText">{t('Your premium access is expired, contact to your subscriptions head')}</p>
              )}
              <div>
                <UserProfileCard
                  username={familyInfo.head.username + ` (${t('Family head')})`}
                  userId={familyInfo.head.id}
                  points={familyInfo.head.points}
                  frontIconUrl="/icon_front.png"
                  backIconUrl="/icon_back.png"
                  rankIconUrl="/rank.png"
                />
                <div className="add-member-section">
                  <button className="button1 leave-button" onClick={handleLeaveFamily}>
                    {t('Leave Family Subscription')}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {(isHead || familyInfo === null) && (
        <div className="add-member-section">
          <button onClick={showAddMemberModal} className="button1">
            {t('Add Member')}
          </button>
        </div>
      )}
    </div>
  );
};

export default FamilySubscriptionManager;
