import React from 'react';
import './RussiaSubscriptionOffer.css';

const RussiaSubscriptionOffer = () => {
  return (
    <div className="russia-subscription-offer">
      <h2 className="russia-subscription-offer__title">
        Доступна оплата через российские карты
      </h2>
    </div>
  );
};

export default RussiaSubscriptionOffer;
