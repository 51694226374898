// src/components/ProfilePage/Shop/ShopDiamonds.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import './ShopDiamonds.css'; // Подключаем стили

const ShopDiamonds = ({productsInfo, prices, onPurchased}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();


  const getPriceText = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product ? `${product.price} ${product.currency}` : '';
  };

  const getPriceCurrency = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product.currency;
  };

  const getPrice = (id) => {
    const product = productsInfo.products.find(product => product.id === id);
    return product.price;
  };
  // Предустановленные товары (5 штук)
  let products = [
    {
      id: 'diamonds_pack_1',
      discount: null,
      image: '/ShopDiamonds/1.png',
    },
    {
      id: 'diamonds_pack_2',
      discount: null,
      image: '/ShopDiamonds/2.png',
    },
    {
      id: 'diamonds_pack_3',
      discount: 10,  // +10%
      image: '/ShopDiamonds/3.png',
    },
    {
      id: 'diamonds_pack_4',
      discount: 20,  // +20%
      image: '/ShopDiamonds/4.png',
    },
    {
      id: 'diamonds_pack_5',
      discount: 50,  // +50%
      image: '/ShopDiamonds/5.png',
    },
  ];

  if (prices && prices.DIAMONDS_PACKS)
    for (let pack of products) {
      if (prices.DIAMONDS_PACKS[pack.id] !== undefined) {
        pack.diamonds = prices.DIAMONDS_PACKS[pack.id];
        pack.price = getPriceText(pack.id);
      }
    }



  const getLanguage = () => {

    const languageMap = {
      'ru': 'ru-RU',
      'en': 'en-US',
      'de': 'de-DE',
      'pl': 'pl',
      'pt': 'pt',
      'tr': 'tr-TR',
      'es': 'es-ES',
      'it': 'it'
    };

    // Получаем текущий язык из i18n и соответствующий ему код или код для английского языка по умолчанию
    const currentLanguageCode = languageMap[i18n.language] || 'en-US';

    return currentLanguageCode;
  }

  const handlePayment = (packId) => {
    // Проверяем, что CloudPayments загружен

    const product = productsInfo.products.find(product => product.id === packId);

    let chargeObject = {
      publicId: productsInfo.publicId, // Замените на ваш Public ID
      description: t(`${packId}`) || "Unknown", // Описание платежа
      amount: getPrice(packId), // Сумма платежа
      currency: getPriceCurrency(packId), // Валюта платежа
      accountId: productsInfo.userId,
      requireEmail: true, // Идентификатор пользователя
      skin: 'mini', // Дизайн виджета
    };

    chargeObject.data = {
      type: product.type,
      productId: product.id,
      grantAccess: product.grantAccess || product.id,
      grantItems: product.grantItems
    }

    if (window.cp) {
      const widget = new window.cp.CloudPayments({ language: getLanguage() });
      widget.charge(chargeObject,
        (options) => { // Success
          onPurchased();
          console.log('Оплата прошла успешно', options);
        },
        (reason, options) => { // Fail
          console.error('Ошибка при оплате', reason, options);
        }
      );
    } else {
      console.error('CloudPayments widget не загружен');
    }
  };

  return (
    <div className="profile-section">
      <h2 className="profile-section-title">{t('Shop')}</h2>
      <div className="shop-grid">
        {products.map((product) => (
          <div key={product.id} className="shop-card profile-section-white-box">
            {/* ВЕРХ: Картинка */}
            <div className="shop-card-top">
              <img
                src={product.image}
                alt={`Product #${product.id}`}
                className="shop-card-image"
              />
            </div>

            {/* СЕРЕДИНА: Кол-во алмазов + скидка */}
            <div className="shop-card-middle">
              <h3 className="shop-card-title">
                {product.diamonds}{' '}
                <img
                  src="/ShopDiamonds/diamonds.png"
                  alt="Diamonds"
                  className="diamond-icon"
                />
              </h3>
              {product.discount && (
                <div className="discount-badge">+{product.discount}%</div>
              )}
            </div>

            {/* НИЗ: Кнопка покупки */}
            <div className="shop-card-bottom">
              <button
                className="button1 diamond-buy-bt"
                onClick={() => handlePayment(product.id)}
              >
                {product.price}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopDiamonds;
