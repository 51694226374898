// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthMethods from '../components/AuthMethods/AuthMethods';

// Импортируем компонент из отдельного файла
import RussiaSubscriptionOffer from '../components/HomePage/RussiaSubscriptionOffer';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [geoInfo, setGeoInfo] = useState(null);

  useEffect(() => {
    // Проверка наличия токенов в localStorage
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    // Если токены есть, перенаправляем на страницу профиля
    if (accessToken && refreshToken) {
      navigate('/profile');
    }
  }, [navigate]);

  useEffect(() => {
    // Запрашиваем данные по геолокации
    const fetchGeoData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/user/getGeo`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setGeoInfo(data);
      } catch (err) {
        console.error('Ошибка при получении данных о геолокации:', err);
      }
    };

    fetchGeoData();
  }, []);

  return (
    <div className="container">
      {/* Если страна Россия, показываем специальное предложение */}
      {geoInfo?.country === 'RU' && <RussiaSubscriptionOffer />}
      {/* <RussiaSubscriptionOffer /> */}
      <AuthMethods providers={[]} allowRegistartion={false} addToken={false} />
    </div>
  );
};

export default HomePage;
