import React, { useEffect } from 'react';
import './Modal.css';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/icons/close-icon.png'

const Modal = ({ title, content, onClose, closeButtonType }) => {
  const { t } = useTranslation();
  useEffect(() => {
    // Функция для вычисления ширины полосы прокрутки
    const getScrollbarWidth = () => {
      return window.innerWidth - document.documentElement.clientWidth;
    };

    const scrollbarWidth = getScrollbarWidth();

    // Добавляем отступ справа, чтобы компенсировать исчезновение полосы прокрутки
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflow = 'hidden'; // Блокируем прокрутку

    return () => {
      // Убираем отступ и восстанавливаем прокрутку
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {closeButtonType === 'button' ? (
          <>
            {title && <h2>{title}</h2>}
            {content && <div>{content}</div>}

            <button className="button1 button-close" onClick={onClose}>
              {t('Modal close button')}
            </button>
            
          </>
        ) : (
          <>
            <div className="modal-header">
              {title && <h2 className="modal-title">{title}</h2>}
              <button className="modal-close-icon" onClick={onClose}>
                <img src={closeIcon} alt="Close" className="modal-close-image" />
              </button>
            </div>
            {content && <div>{content}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
